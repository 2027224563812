import React from "react";
import { useRecoilValue } from "recoil";
import { routePaths } from "../../routes";
import { notifications as notificationsAtom } from "../../states/notificationsState";
import SidenavComponent from "../../vendor/libs/sidenav/SidenavComponent";
import SidenavRouterLink from "../../vendor/libs/sidenav/SidenavRouterLink";

interface IProps {
  orientation?: "vertical" | "horizontal";
}

const LayoutSidenav = ({ orientation = "vertical" }: IProps) => {
  const notifications = useRecoilValue(notificationsAtom);

  const getClassName = () => {
    let bg = "sidenav-theme";

    if (
      orientation === "horizontal" &&
      (bg.indexOf(" sidenav-dark") !== -1 ||
        bg.indexOf(" sidenav-light") !== -1)
    ) {
      bg = bg
        .replace(" sidenav-dark", "")
        .replace(" sidenav-light", "")
        .replace("-darker", "")
        .replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (orientation !== "horizontal"
        ? "layout-sidenav"
        : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  };

  return (
    <SidenavComponent orientation={orientation} className={getClassName()}>
      {/* Inner */}
      <div
        className={`sidenav-inner ${
          orientation !== "horizontal" ? "py-1" : ""
        }`}
      >
        <SidenavRouterLink
          to={routePaths.default}
          exact={true}
          icon="far fa-bookmark"
        >
          Mes réservations
        </SidenavRouterLink>
        <SidenavRouterLink
          to={routePaths.messages}
          exact={true}
          icon="fas fa-envelope"
          badgeText={
            notifications?.messages! > 0
              ? notifications?.messages?.toString()
              : ""
          }
          badgeVariant="primary"
        >
          Mes messages
        </SidenavRouterLink>
        <SidenavRouterLink
          to={routePaths.tribe}
          exact={true}
          icon="fas fa-child"
          badgeText={
            notifications?.requests! > 0
              ? notifications?.requests?.toString()
              : ""
          }
          badgeVariant="primary"
        >
          Ma tribu
        </SidenavRouterLink>
        <SidenavRouterLink
          to={routePaths.services}
          exact={true}
          icon="fas fa-dice-d6"
        >
          Mes services
        </SidenavRouterLink>

        <SidenavRouterLink
          to={routePaths.documents}
          exact={true}
          icon="fas fa-file"
        >
          Mes documents
        </SidenavRouterLink>
        <SidenavRouterLink
          to={routePaths.invoices}
          exact={true}
          icon="fas fa-file-invoice-dollar"
        >
          Mes factures
        </SidenavRouterLink>
      </div>
    </SidenavComponent>
  );
};

export default LayoutSidenav;
