const VanillaDate = Date;

declare global {
  interface DateConstructor {
    new (...args: number[]): Date;
  }
}

/** Monkey patches Date to where calls to Date.now() and new Date() will return another Date.  */
export function timeTravelTo(dateFactory: () => Date): void {
  timeTravelToNow();
  monkeyPatchDate(dateFactory());
}

export function timeTravelToNow(): void {
  // eslint-disable-next-line
  Date = VanillaDate;
}

export function getVanillaDate(): DateConstructor {
  return VanillaDate;
}

function monkeyPatchDate(date: Date): void {
  const newDate = function (...args: number[]) {
    if (args.length > 0) {
      return new VanillaDate(...args);
    }
    return new.target ? date : date.toString();
  };

  newDate.now = () => date.getTime();
  newDate.UTC = VanillaDate.UTC;
  newDate.parse = VanillaDate.parse;
  newDate.prototype = VanillaDate.prototype;

  // eslint-disable-next-line
  Date = newDate as any;
}
