import { Mutex } from "async-mutex";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { parseISO, format } from "date-fns";
import { fr } from "date-fns/locale";
import { routePaths } from "../routes";
import { getExpiration, IToken } from "../states/userState";
import { getVanillaDate } from "./dateProvider";
import { tokenStorage } from "./tokenStorage";
import Qs from "qs";
import { history } from "../index";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss";
const mutex = new Mutex();
let precedingToken = "";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    config.paramsSerializer = (params: any) => {
      return Qs.stringify(params, {
        arrayFormat: "brackets",
        serializeDate: (date: Date) => format(date, dateFormat, { locale: fr }),
      });
    };
    return getAuthentication(config);
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    handleDates(response);
    return response;
  },
  (error) => {
    if (error.message === "Network Error" && !error.response) {
      console.error("Network error - make sure API is running!");
    } else {
      const { status, headers } = error.response;
      if (status === 404) {
        history.push(routePaths.notfound);
      } else if (status === 500) {
        history.push(routePaths.error);
      } else if (error.config.url === routePaths.accountRefresh) {
        tokenStorage.clear();
        history.push(routePaths.default);
      } else if (status === 401) {
        if (headers["x-token-expired"] === "true") {
          return getNewToken()
            .then((token) => {
              const config = error.config;
              config.headers["Authorization"] = `Bearer ${token}`;

              return new Promise((resolve, reject) => {
                axios
                  .request(config)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        } else {
          window.location.href = routePaths.unauthorized;
          return;
        }
      }
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object") return;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === "object") handleDates(value);
  }
}

const getAuthentication = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  let token = tokenStorage.getToken();

  if (token) {
    if (!assertAlive(getExpiration(token))) {
      token = await getNewToken();
    }
    if (token) config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const assertAlive = (token: IToken | null): boolean => {
  if (token === null) return false;

  const now = getVanillaDate().now().valueOf() / 1000;

  if (typeof token.exp !== undefined && token.exp < now) {
    return false;
  }

  return !(typeof token.nbf !== undefined && token.nbf > now);
};

const getNewToken = async (): Promise<string> => {
  const wasLocked = mutex.isLocked();
  const release = await mutex.acquire();
  try {
    if (wasLocked) return precedingToken;

    const instance = axios.create();
    const response = await instance.post(routePaths.accountRefresh, {
      accesstoken: tokenStorage.getToken(),
    });
    const token = response.data.payload.accessToken;
    tokenStorage.storeTokenAuto(token);
    precedingToken = token;
    return token;
  } finally {
    release();
  }
};

const responseBody = (response: AxiosResponse) => response?.data;

export const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (
    url: string,
    file?: Blob,
    filePropertyName?: string,
    body?: any
  ) => {
    let formData = new FormData();
    if (file && filePropertyName) formData.append(filePropertyName, file);
    if (body)
      Object.keys(body).forEach((p) => {
        let value = body[p];
        if (value instanceof Date)
          value = format(value, dateFormat, { locale: fr });
        else if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(p, item);
          });
          return;
        }

        if (value) formData.append(p, value);
      });
    return axios
      .post(url, formData, {
        headers: {
          accept: "application/json",
          "Content-type": "multipart/form-data",
        },
      })
      .then(responseBody);
  },
  postFormMultipleFiles: (
      url: string,
      files?: (Blob | undefined)[],
      filePropertyNames?: string[],
      body?: any
  ) => {
    let formData = new FormData();
    if (files && filePropertyNames) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file !== undefined)
          formData.append(filePropertyNames[i], file); 
      }
    }
    if (body)
      Object.keys(body).forEach((p) => {
        let value = body[p];
        if (value instanceof Date)
          value = format(value, dateFormat, { locale: fr });
        else if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(p, item);
          });
          return;
        }

        if (value) formData.append(p, value);
      });
    return axios
    .post(url, formData, {
      headers: {
        accept: "application/json",
        "Content-type": "multipart/form-data",
      },
    })
    .then(responseBody);
  },
  downloadFile: (url: string, filename?: string) =>
    axios
      .get(url, {
        responseType: "blob",
      })
      .then(
        (response) => {
          if (!filename) {
            const contentDisposition = response.headers["content-disposition"];
            const match = contentDisposition.match(/filename\s*=\s*"?(.+)"?;/i);
            filename = match[1];
          }

          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", filename!);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100);
        },
        (reason) => {
          alert("Désolé, une erreur inattendue est survenue...");
        }
      ),
};
