import React from "react";
import { Link, useParams } from "react-router-dom";
import { routePaths } from "../../routes";

const LayoutFooter = () => {
  const { providerId } = useParams<{
    providerId: string;
  }>();
  
  return (
    <nav className={`layout-footer footer bg-footer-theme`}>
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="pt-3">
          <span className="footer-text font-weight-bolder">
            {new Date().getFullYear()}
          </span>{" "}
          ©&nbsp;<span className="footer-text font-weight-bolder">BinR</span>
        </div>
        <div>
          <Link to={routePaths.providerContact.replace(":providerId", providerId)} className="footer-link pt-3 ml-4">
            Contact
          </Link>
          <Link
            to={routePaths.providerReleaseNote.replace(":providerId", providerId)}
            className="footer-link pt-3 ml-4"
          >
            Version 2.0.1
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default LayoutFooter;
