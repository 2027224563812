import React from "react";
import { useRecoilValue } from "recoil";
import { routePaths } from "../../routes";
import SidenavComponent from "../../vendor/libs/sidenav/SidenavComponent";
import SidenavRouterLink from "../../vendor/libs/sidenav/SidenavRouterLink";
import SidenavMenu from "../../vendor/libs/sidenav/SidenavMenu";
import { useLocation } from "react-router-dom";
import { claimsPerProvider } from "../../states/userState";

interface IProps {
  orientation?: "vertical" | "horizontal";
}

const LayoutSidenav = ({ orientation = "vertical" }: IProps) => {
  const claims = useRecoilValue(claimsPerProvider);
  const location = useLocation();

  const getClassName = () => {
    let bg = "sidenav-theme";

    if (
      orientation === "horizontal" &&
      (bg.indexOf(" sidenav-dark") !== -1 ||
        bg.indexOf(" sidenav-light") !== -1)
    ) {
      bg = bg
        .replace(" sidenav-dark", "")
        .replace(" sidenav-light", "")
        .replace("-darker", "")
        .replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (orientation !== "horizontal"
        ? "layout-sidenav"
        : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  };

  return (
    <SidenavComponent orientation={orientation} className={getClassName()}>
      <div
        className={`sidenav-inner ${
          orientation !== "horizontal" ? "py-1" : ""
        }`}
      >
        {claims && (
          <div>
            {claims.length === 1 ? (
              <SidenavRouterLink
                to={routePaths.provider.replace(
                  ":providerId",
                  claims[0].provider.id
                )}
                exact={true}
                icon="fas fa-cloud-sun"
              >
                Mon prévisionnel
              </SidenavRouterLink>
            ) : (
              <SidenavMenu icon="fas fa-cloud-sun" linkText="Mes prévisionnels">
                {claims.map((c, index) => (
                  <SidenavRouterLink
                    key={index}
                    to={routePaths.provider.replace(
                      ":providerId",
                      c.provider.id
                    )}
                    exact={true}
                  >
                    {c.provider.name}
                    {c.provider.subName && ` - ${c.provider.subName}`}
                  </SidenavRouterLink>
                ))}
              </SidenavMenu>
            )}
            {claims.length === 1 &&
            (claims[0].isAdministrator ||
              claims[0].claims?.includes("messages")) ? (
              <SidenavRouterLink
                to={routePaths.providerMessages.replace(
                  ":providerId",
                  claims[0].provider.id
                )}
                exact={true}
                icon="fas fa-envelope"
                badgeText={
                  claims[0].provider.messages! > 0
                    ? claims[0].provider.messages?.toString()
                    : ""
                }
                badgeVariant="primary"
              >
                Mes messages
              </SidenavRouterLink>
            ) : (
              <SidenavMenu
                icon="fas fa-envelope"
                linkText="Mes messages"
                open={location.pathname.includes("/messages")}
              >
                {claims
                  .filter(
                    (c) => c.isAdministrator || c.claims?.includes("messages")
                  )
                  .map((c, index) => (
                    <SidenavRouterLink
                      key={index}
                      to={routePaths.providerMessages.replace(
                        ":providerId",
                        c.provider.id
                      )}
                      exact={true}
                      badgeText={
                        c.provider.messages! > 0
                          ? c.provider.messages?.toString()
                          : ""
                      }
                      badgeVariant="primary"
                    >
                      {c.provider.name}
                      {c.provider.subName && ` - ${c.provider.subName}`}
                    </SidenavRouterLink>
                  ))}
              </SidenavMenu>
            )}

            {claims.length === 1 &&
            (claims[0].isAdministrator ||
              claims[0].claims?.includes("checking")) &&
            claims[0].provider.services.length === 1 ? (
              <SidenavRouterLink
                to={routePaths.providerChecking
                  .replace(":providerId", claims[0].provider.id)
                  .replace(":serviceId", claims[0].provider.services[0].id)}
                exact={true}
                icon="fas fa-calendar-check"
              >
                Mon relevé
              </SidenavRouterLink>
            ) : (
              <SidenavMenu
                icon="fas fa-calendar-check"
                linkText="Mes relevés"
                open={location.pathname.includes("/checking/")}
              >
                {claims
                  .filter(
                    (c) => c.isAdministrator || c.claims?.includes("checking")
                  )
                  .map((c, index) =>
                    c.provider.services.map((s, index2) => (
                      <SidenavRouterLink
                        key={`${index}-${index2}`}
                        to={routePaths.providerChecking
                          .replace(":providerId", c.provider.id)
                          .replace(":serviceId", s.id)}
                        exact={true}
                      >
                        {s.name}
                      </SidenavRouterLink>
                    ))
                  )}
              </SidenavMenu>
            )}
            {claims.length === 1 &&
            (claims[0].isAdministrator ||
              claims[0].claims?.includes("billing")) ? (
              <SidenavRouterLink
                to={routePaths.providerBilling.replace(
                  ":providerId",
                  claims[0].provider.id
                )}
                exact={true}
                icon="fas fa-file-invoice-dollar"
              >
                Ma facturation
              </SidenavRouterLink>
            ) : (
              <SidenavMenu
                icon="fas fa-file-invoice-dollar"
                linkText="Mes facturations"
                open={location.pathname.includes("/billing")}
              >
                {claims
                  .filter(
                    (c) => c.isAdministrator || c.claims?.includes("billing")
                  )
                  .map((c, index) => (
                    <SidenavRouterLink
                      key={index}
                      to={routePaths.providerBilling.replace(
                        ":providerId",
                        c.provider.id
                      )}
                      exact={true}
                    >
                      {c.provider.name}
                      {c.provider.subName && ` - ${c.provider.subName}`}
                    </SidenavRouterLink>
                  ))}
              </SidenavMenu>
            )}
            <SidenavMenu
              icon="fas fa-chart-bar"
              linkText="Mes statistiques"
              open={location.pathname.includes("/statistics")}
            >
              {claims.length === 1 &&
              (claims[0].isAdministrator ||
                claims[0].claims?.includes("analytics")) ? (
                <>
                  <SidenavRouterLink
                    to={routePaths.providerStatisticsAnalytics.replace(
                      ":providerId",
                      claims[0].provider.id
                    )}
                    exact={true}
                    icon="fas fa-table"
                  >
                    Mes analyses
                  </SidenavRouterLink>
                  <SidenavRouterLink
                    to={routePaths.providerStatisticsIncomes.replace(
                      ":providerId",
                      claims[0].provider.id
                    )}
                    exact={true}
                    icon="fas fa-chart-line"
                  >
                    Mes revenus
                  </SidenavRouterLink>
                  <SidenavRouterLink
                      to={routePaths.providerStatisticsBookings.replace(
                          ":providerId",
                          claims[0].provider.id
                      )}
                      exact={true}
                      icon="fas fa-chart-line"
                  >
                    Mes réservations
                  </SidenavRouterLink>
                  <SidenavRouterLink
                      to={routePaths.providerStatisticsSchedules.replace(
                          ":providerId",
                          claims[0].provider.id
                      )}
                      exact={true}
                      icon="fas fa-chart-line"
                  >
                    Mes horaires
                  </SidenavRouterLink>
                  <SidenavRouterLink
                    to={routePaths.providerStatisticsSociology.replace(
                      ":providerId",
                      claims[0].provider.id
                    )}
                    exact={true}
                    icon="fas fa-chart-bar"
                  >
                    Ma sociologie
                  </SidenavRouterLink>
                </>
              ) : (
                <>
                  <SidenavMenu
                    icon="fas fa-table"
                    linkText="Mes analyses"
                    open={location.pathname.includes("/statistics/analytics")}
                  >
                    {claims
                      .filter(
                        (c) =>
                          c.isAdministrator || c.claims?.includes("analytics")
                      )
                      .map((c, index) => (
                        <SidenavRouterLink
                          key={index}
                          to={routePaths.providerStatisticsAnalytics.replace(
                            ":providerId",
                            c.provider.id
                          )}
                          exact={true}
                        >
                          {c.provider.name}
                          {c.provider.subName && ` - ${c.provider.subName}`}
                        </SidenavRouterLink>
                      ))}
                  </SidenavMenu>
                  <SidenavMenu
                    icon="fas fa-chart-line"
                    linkText="Mes revenus"
                    open={location.pathname.includes("/statistics/incomes")}
                  >
                    {claims
                      .filter(
                        (c) =>
                          c.isAdministrator || c.claims?.includes("analytics")
                      )
                      .map((c, index) => (
                        <SidenavRouterLink
                          key={index}
                          to={routePaths.providerStatisticsIncomes.replace(
                            ":providerId",
                            c.provider.id
                          )}
                          exact={true}
                        >
                          {c.provider.name}
                          {c.provider.subName && ` - ${c.provider.subName}`}
                        </SidenavRouterLink>
                      ))}
                  </SidenavMenu>
                  <SidenavMenu
                      icon="fas fa-chart-line"
                      linkText="Mes réservations"
                      open={location.pathname.includes("/statistics/bookings")}
                  >
                    {claims
                    .filter(
                        (c) =>
                            c.isAdministrator || c.claims?.includes("analytics")
                    )
                    .map((c, index) => (
                        <SidenavRouterLink
                            key={index}
                            to={routePaths.providerStatisticsBookings.replace(
                                ":providerId",
                                c.provider.id
                            )}
                            exact={true}
                        >
                          {c.provider.name}
                          {c.provider.subName && ` - ${c.provider.subName}`}
                        </SidenavRouterLink>
                    ))}
                  </SidenavMenu>
                  <SidenavMenu
                      icon="fas fa-chart-line"
                      linkText="Mes horaires"
                      open={location.pathname.includes("/statistics/schedules")}
                  >
                    {claims
                    .filter(
                        (c) =>
                            c.isAdministrator || c.claims?.includes("analytics")
                    )
                    .map((c, index) => (
                        <SidenavRouterLink
                            key={index}
                            to={routePaths.providerStatisticsSchedules.replace(
                                ":providerId",
                                c.provider.id
                            )}
                            exact={true}
                        >
                          {c.provider.name}
                          {c.provider.subName && ` - ${c.provider.subName}`}
                        </SidenavRouterLink>
                    ))}
                  </SidenavMenu>
                  <SidenavMenu
                      icon="fas fa-chart-bar"
                      linkText="Mes sociologies"
                      open={location.pathname.includes("/statistics/sociology")}
                  >
                    {claims
                    .filter(
                        (c) =>
                            c.isAdministrator || c.claims?.includes("analytics")
                    )
                    .map((c, index) => (
                        <SidenavRouterLink
                            key={index}
                            to={routePaths.providerStatisticsSociology.replace(
                                ":providerId",
                                c.provider.id
                            )}
                            exact={true}
                        >
                          {c.provider.name}
                          {c.provider.subName && ` - ${c.provider.subName}`}
                        </SidenavRouterLink>
                    ))}
                  </SidenavMenu>
                </>
              )}
            </SidenavMenu>
            {claims.length === 1 &&
            (claims[0].isAdministrator ||
              claims[0].claims?.includes("analytics")) ? (
              <SidenavRouterLink
                to={routePaths.providerExports.replace(
                  ":providerId",
                  claims[0].provider.id
                )}
                exact={true}
                icon="fas fa-file-export"
              >
                Mes exports
              </SidenavRouterLink>
            ) : (
              <SidenavMenu
                icon="fas fa-file-export"
                linkText="Mes exports"
                open={location.pathname.includes("/exports")}
              >
                {claims
                  .filter(
                    (c) => c.isAdministrator || c.claims?.includes("exports")
                  )
                  .map((c, index) => (
                    <SidenavRouterLink
                      key={index}
                      to={routePaths.providerExports.replace(
                        ":providerId",
                        c.provider.id
                      )}
                      exact={true}
                    >
                      {c.provider.name}
                      {c.provider.subName && ` - ${c.provider.subName}`}
                    </SidenavRouterLink>
                  ))}
              </SidenavMenu>
            )}
            {claims.length === 1 &&
            (claims[0].isAdministrator ||
              claims[0].claims?.includes("subscribers")) ? (
              <SidenavRouterLink
                to={routePaths.providerSubscribers.replace(
                  ":providerId",
                  claims[0].provider.id
                )}
                exact={true}
                icon="fas fa-users"
              >
                Mes abonnés
              </SidenavRouterLink>
            ) : (
              <SidenavMenu
                icon="fas fa-users"
                linkText="Mes abonnés"
                open={location.pathname.includes("/subscribers")}
              >
                {claims
                  .filter(
                    (c) =>
                      c.isAdministrator || c.claims?.includes("subscribers")
                  )
                  .map((c, index) => (
                    <SidenavRouterLink
                      key={index}
                      to={routePaths.providerSubscribers.replace(
                        ":providerId",
                        c.provider.id
                      )}
                      exact={true}
                    >
                      {c.provider.name}
                      {c.provider.subName && ` - ${c.provider.subName}`}
                    </SidenavRouterLink>
                  ))}
              </SidenavMenu>
            )}
            {claims.length === 1 && claims[0].isAdministrator ? (
              <SidenavRouterLink
                to={routePaths.providerAccount.replace(
                  ":providerId",
                  claims[0].provider.id
                )}
                exact={true}
                icon="fas fa-address-card"
              >
                Mon compte
              </SidenavRouterLink>
            ) : (
              <SidenavMenu
                icon="fas fa-address-card"
                linkText="Mes comptes"
                open={location.pathname.endsWith("/account")}
              >
                {claims
                  .filter((c) => c.isAdministrator)
                  .map((c, index) => (
                    <SidenavRouterLink
                      key={index}
                      to={routePaths.providerAccount.replace(
                        ":providerId",
                        c.provider.id
                      )}
                      exact={true}
                    >
                      {c.provider.name}
                      {c.provider.subName && ` - ${c.provider.subName}`}
                    </SidenavRouterLink>
                  ))}
              </SidenavMenu>
            )}
            {claims.length === 1 && claims[0].isAdministrator ? (
              <SidenavRouterLink
                to={routePaths.providerServices.replace(
                  ":providerId",
                  claims[0].provider.id
                )}
                exact={true}
                icon="fas fa-cogs"
              >
                Mes services
              </SidenavRouterLink>
            ) : (
              <SidenavMenu
                icon="fas fa-cogs"
                linkText="Mes services"
                open={location.pathname.includes("/services")}
              >
                {claims
                  .filter((c) => c.isAdministrator)
                  .map((c, index) => (
                    <SidenavRouterLink
                      key={index}
                      to={routePaths.providerServices.replace(
                        ":providerId",
                        c.provider.id
                      )}
                      exact={true}
                    >
                      {c.provider.name}
                      {c.provider.subName && ` - ${c.provider.subName}`}
                    </SidenavRouterLink>
                  ))}
              </SidenavMenu>
            )}
          </div>
        )}
      </div>
    </SidenavComponent>
  );
};

export default LayoutSidenav;
