import jwt_decode from "jwt-decode";
import { atom, selector } from "recoil";
import { AccountController } from "../apis/accountController";
import { tokenStorage } from "../core/tokenStorage";
import { IResult as GetCurrentUserResult } from "../models/Account/CurrentUser/IResult";
import { IService } from "../models/Providers/GetAll/IService";
import { providers } from "./providersState";

export interface IToken {
  exp: number;
  nbf: number;
}

interface IDecodedToken extends IToken {
  nameid: string;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role":
    | string
    | string[]
    | undefined;
  "https://www.monpetitagenda.com/claims/isadministratorof":
    | string
    | string[]
    | undefined;
}

export interface IUser extends GetCurrentUserResult {}

const asyncDefault = selector<IUser | null>({
  key: "userStateAsyncDefault",
  async get() {
    if (tokenStorage.hasAuthenticationStored()) {
      try {
        const result = await AccountController.getCurrentUser();
        if (result === null) {
          tokenStorage.clear();
        } else {
          tokenStorage.storeToken(result.accessToken, true);
          return result;
        }
      } catch (e) {
        console.error(e);
        tokenStorage.clear();
      }
    }

    return null;
  },
});

export const loggedInUser = atom({
  key: "loggedInUser",
  default: asyncDefault,
});

export const loggedInUserFullName = selector<string>({
  key: "loggedInUserFullName",
  get: ({ get }) => {
    const user = get(loggedInUser);
    return user !== null ? `${user.firstName} ${user.lastName}` : "Inconnu";
  },
});

export const loggedInUserPicture = selector<string>({
  key: "loggedInUserPicture",
  get: ({ get }) => {
    const user = get(loggedInUser);
    return user !== null ? user.picture ?? "" : "";
  },
});

export const loggedInUserEmail = selector<string>({
  key: "loggedInUserEmail",
  get: ({ get }) => {
    const user = get(loggedInUser);
    return user !== null ? user.userName : "";
  },
});

export const loggedInUserIsProvider = selector<boolean>({
  key: "loggedInUserIsProvider",
  get: ({ get }) => {
    const user = get(loggedInUser);
    if (!user) return false;

    const roles = decodeToken(user.accessToken)?.[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
    return (
      roles !== undefined &&
      (roles === "Provider" || roles.includes("Provider"))
    );
  },
});

export const loggedInUserIsAdministrator = selector<boolean>({
  key: "loggedInUserIsAdministrator",
  get: ({ get }) => {
    const user = get(loggedInUser);
    if (!user) return false;

    const roles = decodeToken(user.accessToken)?.[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
    return (
      roles !== undefined &&
      (roles === "Administrator" || roles.includes("Administrator"))
    );
  },
});

export const loggedInUserIsDelegate = selector<boolean>({
  key: "loggedInUserIsDelegate",
  get: ({ get }) => {
    const user = get(loggedInUser);
    if (!user) return false;

    const roles = decodeToken(user.accessToken)?.[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
    return (
      roles !== undefined &&
      (roles === "Delegate" || roles.includes("Delegate"))
    );
  },
});

export const loggedInUserFirstProviderId = selector<string | undefined>({
  key: "loggedInUserFirstProviderId",
  get: ({ get }) => {
    if (
      !get(loggedInUserIsProvider) &&
      !get(loggedInUserIsDelegate) &&
      !get(loggedInUserIsAdministrator)
    )
      return undefined;

    const availableProviders = get(providers);
    if (availableProviders === null || availableProviders.length === 0)
      return undefined;

    return availableProviders[0].id;
  },
});

export const loggedInUserId = selector<string | undefined>({
  key: "loggedInUserId",
  get: ({ get }) => {
    const user = get(loggedInUser);
    if (!user) return undefined;

    return decodeToken(user.accessToken)?.nameid;
  },
});

export const isLoggedIn = selector<boolean>({
  key: "isLoggedIn",
  get: ({ get }) => {
    const user = get(loggedInUser);
    return user !== null;
  },
});

export interface IClaimsPerProvider {
  claims: string[];
  provider: {
    id: string;
    name: string;
    subName: string;
    messages: number;
    services: IService[];
  };
  isAdministrator: boolean;
}

export const claimsPerProvider = selector<IClaimsPerProvider[] | undefined>({
  key: "claimsPerProvider",
  get: ({ get }) => {
    const isAdministrator = get(loggedInUserIsAdministrator);
    if (
      !get(loggedInUserIsProvider) &&
      !get(loggedInUserIsDelegate) &&
      !isAdministrator
    )
      return undefined;

    const availableProviders = get(providers);
    if (availableProviders === null || availableProviders.length === 0)
      return undefined;

    const user = get(loggedInUser);
    if (!user) return undefined;

    const decodedToken = decodeToken(user.accessToken);
    const token: any = decodedToken;
    return availableProviders.map((p) => {
      return {
        claims:
          token[`https://www.monpetitagenda.com/claims/${p.id}/delegations`],
        isAdministrator:
          isAdministrator ||
          (decodedToken?.[
            "https://www.monpetitagenda.com/claims/isadministratorof"
          ]?.includes(p.id) ??
            false),
        provider: p,
      };
    });
  },
});

const decodeToken = (token: string): IDecodedToken | null => {
  if (token === null) return null;

  return jwt_decode(token) as IDecodedToken;
};

export const getExpiration = (token: string): IToken | null => {
  return decodeToken(token);
};
