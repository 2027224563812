import { atom, selector } from "recoil";
import { CoreController } from "../apis/coreController";
import { IResult as INotifications } from "../models/Core/GetNotifications/IResult";

const asyncDefault = selector<INotifications | null>({
  key: "notificationsStateAsyncDefault",
  async get() {
    try {
      return await CoreController.getNotifications();
    } catch (e) {
      console.error(e);
    }
    return null;
  }
});

export const notifications = atom({
  key: "notifications",
  default: asyncDefault,
});
