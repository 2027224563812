import React, { Suspense } from "react";
import Router from "./components/router/router";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/errorBoundary/errorBoundary";
import { RecoilRoot } from "recoil";

import "./vendor/styles/bootstrap-material.scss";
import "./vendor/styles/appwork-material.scss";
import "./vendor/styles/theme-corporate-material.scss";
import "./vendor/styles/colors-material.scss";
import "./vendor/styles/uikit.scss";
import "./vendor/styles/hr-text.scss";
import "./vendor/libs/react-ladda/react-ladda.scss";
import "./vendor/libs/react-select/react-select.scss";
import "./vendor/libs/react-datepicker/react-datepicker.scss";
import 'react-toastify/dist/ReactToastify.min.css';
import "./app.scss";
import Loader from "./components/loader";

const App = () => {
  return (
    <ErrorBoundary>
      <RecoilRoot>
        <Suspense fallback={<Loader />}>
          <Router />
        </Suspense>
        <ToastContainer
          position="bottom-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </RecoilRoot>
    </ErrorBoundary>
  );
};

export default App;
