import { requests } from "../core/proxy";
import { ICommand as AddFacebookCommand } from "../models/Account/AddFacebook/ICommand";
import { IResult as AddFacebookResult } from "../models/Account/AddFacebook/IResult";
import { ICommand as AddGoogleCommand } from "../models/Account/AddGoogle/ICommand";
import { IResult as AddGoogleResult } from "../models/Account/AddGoogle/IResult";
import { ICommand as CreateAccountCommand } from "../models/Account/CreateAccount/ICommand";
import { ICommand as ResendEmailCommand } from "../models/Account/ResendEmail/ICommand";
import { ICommand as CreateLocalAccountCommand } from "../models/Account/CreateLocalAccount/ICommand";
import { IResult as GetCurrentUserResult } from "../models/Account/CurrentUser/IResult";
import { ICommand as LoginWithFacebookCommand } from "../models/Account/Facebook/ICommand";
import { IResult as LoginWithFacebookResult } from "../models/Account/Facebook/IResult";
import { ICommand as ForgotPasswordCommand } from "../models/Account/ForgotPassword/ICommand";
import { ICommand as LoginWithGoogleCommand } from "../models/Account/Google/ICommand";
import { IResult as LoginWithGoogleResult } from "../models/Account/Google/IResult";
import { ICommand as LoginCommand } from "../models/Account/Login/ICommand";
import { IResult as LoginResult } from "../models/Account/Login/IResult";
import { ICommand as UpdatePasswordCommand } from "../models/Account/UpdatePassword/ICommand";
import { ICommandResult } from "../models/ICommandResult";

export const AccountController = {
  login: (command: LoginCommand): Promise<LoginResult> =>
    requests.post(`/account/login`, command),
  logout: (): Promise<ICommandResult> => requests.post(`/account/logout`, {}),
  createAccount: (command: CreateAccountCommand): Promise<ICommandResult> =>
    requests.post(`/account/create`, command),
  resendEmail: (command: ResendEmailCommand): Promise<ICommandResult> =>
      requests.post(`/account/resend`, command),
  createLocalAccount: (
    command: CreateLocalAccountCommand
  ): Promise<ICommandResult> => requests.post(`/account/local`, command),
  updatePassword: (command: UpdatePasswordCommand): Promise<ICommandResult> =>
    requests.post(`/account/updatePassword`, command),
  getCurrentUser: (): Promise<GetCurrentUserResult> => requests.get("/account"),
  addFacebook: (command: AddFacebookCommand): Promise<AddFacebookResult> =>
    requests.post(`/account/facebook/add`, command),
  addGoogle: (command: AddGoogleCommand): Promise<AddGoogleResult> =>
    requests.post(`/account/google/add`, command),
  removeFacebook: (): Promise<ICommandResult> =>
    requests.post(`/account/facebook/remove`, {}),
  removeGoogle: (): Promise<ICommandResult> =>
    requests.post(`/account/google/remove`, {}),
  removeMicrosoft: (): Promise<ICommandResult> =>
    requests.post(`/account/microsoft/remove`, {}),
  loginWithFacebook: (
    command: LoginWithFacebookCommand
  ): Promise<LoginWithFacebookResult> =>
    requests.post(`/account/facebook`, command),
  loginWithGoogle: (
    command: LoginWithGoogleCommand
  ): Promise<LoginWithGoogleResult> =>
    requests.post(`/account/google`, command),
  confirmEmail: (userId: string, code: string): Promise<ICommandResult> =>
    requests.get(`/account/confirm?userId=${userId}&code=${code}`),
  forgotPassword: (command: ForgotPasswordCommand): Promise<ICommandResult> =>
    requests.post(`/account/forgotPassword`, command),
  resetPassword: (command: {
    userId: string;
    code: string;
    password: string;
  }): Promise<ICommandResult> =>
    requests.post(`/account/resetPassword`, command),
};
