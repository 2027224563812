import React, { PropsWithChildren, useLayoutEffect } from "react";
import LayoutFooter from "./layoutFooter";
import LayoutNavbar from "../../_shared/_layouts/layoutNavbar";
import LayoutSidenav from "./layoutSidenav";
import layoutHelpers from "../../core/layoutHelpers";

const AppLayout = (props: PropsWithChildren<any>) => {
  useLayoutEffect(() => {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);

    return () => {
      layoutHelpers.destroy();
    };
  }, []);

  return (
    <div className="layout-wrapper layout-1">
      <div className="layout-inner">
        <LayoutNavbar {...props} sidenavToggle={true} />

        <div className="layout-container" ref={(el) => {
          if (el) {
            el.style.setProperty('padding-top', '70px', 'important');
          }
        }}>
          <LayoutSidenav {...props} />

          <div className="layout-content">
            <div className="container-fluid flex-grow-1 container-p-y">
              {props.children}
            </div>
            <br /><br />
            <LayoutFooter {...props} />
          </div>
        </div>
      </div>
      <div
        className="layout-overlay"
        onClick={() => layoutHelpers.setCollapsed(true)}
      />
    </div>
  );
};

export default AppLayout;
