import React, { useEffect } from "react";
import "./error.scss";
import { useHistory } from "react-router-dom";
import useTitle from "../../core/useTitle";

const Error = () => {
  useTitle("Oups...");
  const history = useHistory();
  document.body.classList.add("bg-primary");

  useEffect(() => {
    return () => {
      document.body.classList.remove("bg-primary");
    };
  }, []);
  return (
    <div className="overflow-hidden">
      <div className="container d-flex align-items-stretch ui-mh-100vh p-0">
        <div className="row w-100">
          <div className="d-flex col-md justify-content-center align-items-center order-2 order-md-1 position-relative p-5">
            <div className="error-bg-skew theme-bg-white" />
            <div className="error-bg-skew theme-bg-white" />

            <div className="text-md-left text-center">
              <h1 className="display-2 font-weight-bolder mb-4">Oups...</h1>
              <div className="text-large font-weight-light mb-5">
                Désolé,
                <br /> il semble qu'il y ait eu un problème que nous n'avions
                pas anticipé :(
              </div>
              <button
                type="button"
                onClick={() => history.go(-1)}
                className="btn btn-primary"
              >
                Page précédente
              </button>
            </div>
          </div>

          <div className="d-flex col-md-5 justify-content-center align-items-center order-1 order-md-2 text-center text-white p-5">
            <div>
              <div className="error-code font-weight-bolder mb-2">500</div>
              <div className="error-description font-weight-light">
                Internal Server Error
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
