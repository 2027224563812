import React, { FormEventHandler, PropsWithChildren, ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda-button";
import "../../vendor/libs/react-ladda/react-ladda.scss";

interface IProps {
  title: string;
  subTitle?: string;
  message?: string;
  subMessage?: string;
  isPending: boolean;
  hide: () => void;
  submit: FormEventHandler<any>;
  onCancel?: () => void;
  size?: "sm" | "lg" | "xl" | undefined;
  bottomChildren?: ReactNode;
  closeLabel?: string;
}

export const FormModal = ({
  title,
  subTitle,
  message,
  subMessage,
  isPending,
  hide,
  submit,
  onCancel,
  children,
  bottomChildren,
  size, 
  closeLabel
}: PropsWithChildren<IProps>) => {
  const cancel = () => {
    if (onCancel) onCancel();
    hide();
  };

  return (
    <Modal show={true} size={size ?? "sm"} onHide={cancel}>
      <form onSubmit={submit}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            {title}
            {subTitle && (
              <span className="font-weight-light">&nbsp;{subTitle}</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && (
            <div>
              {message}
              {subMessage && (
                <span className="font-weight-semibold">&nbsp;{subMessage}</span>
              )}
            </div>
          )}
          {children}
        </Modal.Body>
        <Modal.Footer>
          {bottomChildren}
          <Button variant="default" onClick={cancel}>
            {closeLabel ?? "Annuler" }
          </Button>
          <LaddaButton
            type="submit"
            className="btn btn-primary"
            loading={isPending}
            data-style={ZOOM_IN}
          >
            Valider
          </LaddaButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
