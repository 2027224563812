import { useCallback, useState, useMemo } from "react";
import * as React from "react";
import { ModalType, ModalContext } from "./modalContext";
import { ModalRoot } from "./modalRoot";

/**
 * Modal Provider Props
 */
export interface ModalProviderProps {
  /**
   * Specifies the root element to render modals into
   */
  container?: Element;

  /**
   * Container component for modal nodes
   */
  rootComponent?: React.ComponentType<any>;

  /**
   * Subtree that will receive modal context
   */
  children: React.ReactNode;
}

/**
 * Modal Provider
 *
 * Provides modal context and renders ModalRoot.
 */
export const ModalProvider = ({
  container,
  rootComponent,
  children,
}: ModalProviderProps) => {
  if (container && !(container instanceof HTMLElement)) {
    throw new Error(
      `Container must specify DOM element to mount modal root into.`
    );
  }
  const [modals, setModals] = useState<Record<string, { modal: ModalType<any>, subject: any}>>({});
  const showModal = useCallback(
    (key: string, modal: ModalType<any>, subject: any) => {
      setModals((modals) => ({
        ...modals,
        [key]: { modal, subject },
      }));
    },
    []
  );
  const hideModal = useCallback((key: string) => {
    setModals((modals) => {
      if (!modals[key]) {
        return modals;
      }
      const newModals = { ...modals };
      delete newModals[key];
      return newModals;
    });
  }, []);
  const contextValue = useMemo(() => ({ showModal, hideModal }), [showModal, hideModal]);

  return (
    <ModalContext.Provider value={contextValue}>
      <React.Fragment>
        {children}
        <ModalRoot
          modals={modals}
          component={rootComponent}
          container={container}
        />
      </React.Fragment>
    </ModalContext.Provider>
  );
};
