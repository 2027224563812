import React from "react";
import { Link } from "react-router-dom";
import layoutHelpers from "../../core/layoutHelpers";
import { routePaths } from "../../routes";

const LayoutFooter = () => {
  
  return (
    <nav className={`layout-footer footer bg-footer-theme`}>
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="pt-3">
          <span className="footer-text font-weight-bolder">
            {new Date().getFullYear()}
          </span>{" "}
          ©&nbsp;<span className="footer-text font-weight-bolder">BinR</span>
        </div>
        <div>
          {/*<Link to={routePaths.faq} className={`footer-link pt-3 ml-${layoutHelpers.isSmallScreen() ? "1 footer-mobile" : "4"}`}>
            FAQ
          </Link>*/}
          <Link to={routePaths.contact} className={`footer-link pt-3 ml-${layoutHelpers.isSmallScreen() ? "1 footer-mobile" : "4"}`}>
            Contact
          </Link>
          <Link to={routePaths.terms} target="_blank" className={`footer-link pt-3 ml-${layoutHelpers.isSmallScreen() ? "1 footer-mobile" : "4"}`}>
            Mentions légales
          </Link>
          <Link to={routePaths.policy} target="_blank" className={`footer-link pt-3 ml-${layoutHelpers.isSmallScreen() ? "1 footer-mobile" : "4"}`}>
            Politique de confidentialité
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default LayoutFooter;
