import {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  DependencyList,
} from "react";
import { ModalContext, ModalType } from "./modalContext";

/**
 * Callback types provided for descriptive type-hints
 */
type ShowModal<S> = (subject: S) => void;
type HideModal = () => void;

/**
 * Utility function to generate unique number per component instance
 */
const generateModalKey = (() => {
  let count = 0;

  return () => `${++count}`;
})();

/**
 * Check whether the argument is a stateless component.
 *
 * We take advantage of the stateless nature of functional components to be
 * inline the rendering of the modal component as part of another immutable
 * component.
 *
 * This is necessary for allowing the modal to update based on the inputs passed
 * as the second argument to useModal without unmounting the previous version of
 * the modal component.
 */
const isFunctionalComponent = (Component: Function) => {
  const prototype = Component.prototype;

  return !prototype || !prototype.isReactComponent;
};

/**
 * React hook for showing modal windows
 */
export const useModal = <S = void>(
  component: ModalType<S>,
  inputs: DependencyList = []
): [ShowModal<S>, HideModal] => {
  if (!isFunctionalComponent(component)) {
    throw new Error(
      "Only stateless components can be used as an argument to useModal. You have probably passed a class component where a function was expected."
    );
  }

  const key = useMemo(generateModalKey, []);
  // eslint-disable-next-line
  const modal = useMemo(() => component, inputs);
  const context = useContext(ModalContext);
  const [isShown, setShown] = useState<boolean>(false);
  const [subject, setSubject] = useState<S>();
  const showModal = useCallback((s: S) => {
    setSubject(s);
    setShown(true);
  }, []);
  const hideModal = useCallback(() => setShown(false), []);

  useEffect(() => {
    if (isShown) {
      context.showModal<S>(key, modal, subject!);
    } else {
      context.hideModal(key);
    }

    // Hide modal when parent component unmounts
    return () => context.hideModal(key);
  }, [modal, isShown, context, key, subject]);

  return [showModal, hideModal];
};
