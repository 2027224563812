import React, { PropsWithChildren } from "react";
import { Button, Modal } from "react-bootstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda-button";
import "../../vendor/libs/react-ladda/react-ladda.scss";

interface IProps {
  title: string;
  subTitle?: string;
  message?: string;
  subMessage?: string;
  isPending: boolean;
  hide: () => void;
  action: () => (Promise<void> | Promise<unknown>);
  onCancel?: () => void;
  disabled?: boolean;
  size?: "sm" | "lg" | "xl" | undefined;
}

export const ValidateModal = ({
  title,
  subTitle,
  message,
  subMessage,
  isPending,
  hide,
  action,
  onCancel,
  children,
  disabled, 
  size
}: PropsWithChildren<IProps>) => {
  const cancel = () => {
    if (onCancel) onCancel();
    hide();
  };

  return (
    <Modal show={true} size={ size ?? "sm" } onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          {title}
          {subTitle && (
            <span className="font-weight-light">&nbsp;{subTitle}</span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && (<div>
          {message}
          {subMessage && (
            <span className="font-weight-semibold">&nbsp;{subMessage}</span>
          )}
        </div>)}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={cancel}>
          Annuler
        </Button>
        <LaddaButton
          type="button"
          className="btn btn-primary"
          loading={isPending}
          data-style={ZOOM_IN}
          disabled={disabled}
          onClick={action}
        >
          Valider
        </LaddaButton>
      </Modal.Footer>
    </Modal>
  );
};
