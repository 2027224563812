import { requests } from "../core/proxy";
import { IResult as GetMembers } from "../models/Core/GetMembers/IResult";
import { IResult as GetNotifications } from "../models/Core/GetNotifications/IResult";
import { IResult as GetSubscribedProviders } from "../models/Core/GetSubscribedProviders/IResult";
import { IResult as GetSubscribedServices } from "../models/Core/GetSubscribedServices/IResult";

export const CoreController = {
  getSubscribedProviders: (): Promise<GetSubscribedProviders> =>
    requests.get("/core/subscribedProviders"),
  getSubscribedServices: (): Promise<GetSubscribedServices> =>
    requests.get("/core/subscribedServices"),
  getMembers: (): Promise<GetMembers> => requests.get("/core/members"),
  getNotifications: (): Promise<GetNotifications> =>
    requests.get("/core/notifications"),
  setDate: (date?: Date): Promise<void> =>
      requests.post(`/core/setDate`, { date }),
  getDate: (): Promise<Date | null> =>
      requests.get(`/core/getDate`),
};