import { useEffect, useRef } from "react";
import { titleTemplate } from "../routes";

export interface UseTitleOptions {
    restoreOnUnmount?: boolean;
    template: string;
}

const DEFAULT_USE_TITLE_OPTIONS: UseTitleOptions = {
    restoreOnUnmount: false,
    template: titleTemplate
};

function useTitle(title: string, options: UseTitleOptions = DEFAULT_USE_TITLE_OPTIONS) : string {
    const prevTitleRef = useRef(document.title);
    document.title = options.template.replace("%s", title);
    useEffect(() => {
        const prev = prevTitleRef.current;
        if (options && options.restoreOnUnmount) {
            return () => {
                document.title = prev;
            };
        } else {
            return;
        }
    }, [options]);
    return title;
}

export default typeof document !== 'undefined' ? useTitle : (_title: string) => _title;