import React, { PropsWithChildren, ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

interface IProps {
  title: string;
  subTitle?: string;
  message?: string;
  subMessage?: string;
  hide: () => void;
  className?: string;
  extraButtons?: ReactNode;
  size?: "sm" | "lg" | "xl" | undefined;
}

export const CloseModal = ({
  title,
  subTitle,
  message,
  subMessage,
  hide,
  children,
  extraButtons, 
  size,
  ...rest
}: PropsWithChildren<IProps>) => {
  return (
    <Modal {...rest} size={size ?? "sm"} show={true} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          {title}
          {subTitle && (
            <span className="font-weight-light">&nbsp;{subTitle}</span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && (
          <div>
            {message}
            {subMessage && (
              <span className="font-weight-semibold">&nbsp;{subMessage}</span>
            )}
            &nbsp;?
          </div>
        )}
        {children}
      </Modal.Body>
      <Modal.Footer>
        {extraButtons}
        <Button variant="secondary" block={extraButtons === undefined} onClick={hide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
