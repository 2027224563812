import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { setLocale } from "yup";
import { fr as frYup } from "yup-locales";
import App from "./app";
import { ModalProvider } from "./components/modal";
import "./polyfills";
import * as serviceWorker from "./serviceWorker";

export const history = createBrowserHistory();

setLocale(frYup);

ReactDOM.render(
  //<React.StrictMode>
  <Router history={history}>
    <ModalProvider>
      <App />
    </ModalProvider>
  </Router>,
  //</React.StrictMode>
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
