import React from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Loader from "./components/loader/loader";

// Layouts
import AppLayout from "./_app/_layouts/appLayout";
import ProviderLayout from "./_provider/_layouts/providerLayout";
import LayoutBlank from "./_shared/_layouts/layoutBlank";

// Lazy load component
const lazy = (cb) =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Document title template

export const titleTemplate = "%s - Mon Petit Agenda";

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export class routePaths {
  static default = "/";
  static accountRefresh = "/account/refresh";
  static login = "/login";
  static logout = "/logout";
  static terms = "/terms.html";
  static policy = "/policy.html";
  static resendConfirmation = "/resend";
  static bookService = "/book";
  static bookServiceWithDate = "/book?date=:date";
  static bookMembers = "/book/members";
  static bookDate = "/book/date";
  static bookOptions = "/book/options";
  static bookValidate = "/book/validate";
  static register = "/register";
  static confirmEmail = "/confirmEmail";
  static forgotPassword = "/forgotPassword";
  static resetPassword = "/resetPassword";
  static error = "/error";
  static notfound = "/notfound";
  static unauthorized = "/unauthorized";
  static faq = "/faq";
  static contact = "/contact";
  static settings = "/settings";
  static tribe = "/tribe";
  static tribeMember = "/tribe/:memberId";
  static tribeRegister = "/tribe/register";
  static tribeRequests = "/tribe/requests";
  static invoices = "/invoices";
  static services = "/services";
  static serviceRegister = "/services/register";
  static serviceRegisterWithCode = "/services/register?code=:code";
  static serviceRegisterValidate = "/services/register/validate";
  static serviceDetail = "/services/:serviceId";
  static documents = "/documents";
  static documentsNew = "/documents/new";
  static documentsEdit = "/documents/:documentId";
  static messages = "/messages";
  static messagesCompose = "/messages/compose";
  static messagesReply = "/messages/:messageId/reply";
  static messagesDetail = "/messages/:messageId";
  static provider = "/provider/:providerId";
  static providerChecking = "/provider/:providerId/checking/:serviceId";
  static providerBilling = "/provider/:providerId/billing";
  static providerStatisticsAnalytics = "/provider/:providerId/statistics/analytics";
  static providerStatisticsIncomes = "/provider/:providerId/statistics/incomes";
  static providerStatisticsBookings = "/provider/:providerId/statistics/bookings";
  static providerStatisticsSchedules = "/provider/:providerId/statistics/schedules";
  static providerStatisticsSociology = "/provider/:providerId/statistics/sociology";
  static providerExports = "/provider/:providerId/exports";
  static providerSubscribers = "/provider/:providerId/subscribers";
  static providerMessages = "/provider/:providerId/messages";
  static providerMessagesCompose = "/provider/:providerId/messages/compose";
  static providerMessagesReply =
    "/provider/:providerId/messages/:messageId/reply";
  static providerMessagesDetail = "/provider/:providerId/messages/:messageId";
  static providerMessagesComposeTo =
    "/provider/:providerId/messages/compose?to=:to";
  static providerServices = "/provider/:providerId/services";
  static providerServiceDetail = "/provider/:providerId/services/:serviceId";
  static providerReleaseNote = "/provider/:providerId/releaseNote";
  static providerHelp = "/provider/:providerId/help";
  static providerContact = "/provider/:providerId/contact";
  static providerAccount = "/provider/:providerId/account";
}

export const routes = [
  {
    path: routePaths.default,
    component: lazy(() => import("./_app/home/home")),
    layout: AppLayout,
  },
  {
    path: [routePaths.bookService],
    component: lazy(() => import("./_app/home/book")),
    layout: AppLayout,
  },
  {
    path: [
      routePaths.bookMembers,
      routePaths.bookDate,
      routePaths.bookOptions,
      routePaths.bookValidate,
    ],
    component: lazy(() => import("./_app/home/book")),
    layout: AppLayout,
  },
  {
    path: routePaths.login,
    component: lazy(() => import("./_shared/login/login")),
    layout: LayoutBlank,
    public: true,
  },
  {
    path: routePaths.logout,
    component: lazy(() => import("./_shared/logout/logout")),
    layout: LayoutBlank,
  },
  {
    path: routePaths.resendConfirmation,
    component: lazy(() => import("./_shared/resend/resend")),
    layout: LayoutBlank,
    public: true,
  },
  {
    path: routePaths.register,
    component: lazy(() => import("./_shared/register/register")),
    layout: LayoutBlank,
    public: true,
  },
  {
    path: routePaths.confirmEmail,
    component: lazy(() => import("./_shared/confirmEmail/confirmEmail")),
    layout: LayoutBlank,
    public: true,
  },
  {
    path: routePaths.forgotPassword,
    component: lazy(() => import("./_shared/forgotPassword/forgotPassword")),
    layout: LayoutBlank,
    public: true,
  },
  {
    path: routePaths.resetPassword,
    component: lazy(() => import("./_shared/resetPassword/resetPassword")),
    layout: LayoutBlank,
    public: true,
  },
  {
    path: routePaths.error,
    component: lazy(() => import("./components/error/error")),
    layout: LayoutBlank,
    public: true,
  },
  {
    path: routePaths.unauthorized,
    component: lazy(() => import("./components/unAuthorized/unauthorized")),
    layout: LayoutBlank,
    public: true,
  },
  {
    path: routePaths.faq,
    component: lazy(() => import("./_app/faq/faq")),
    layout: AppLayout,
  },
  {
    path: routePaths.contact,
    component: lazy(() => import("./_app/contact/contact")),
    layout: AppLayout,
  },
  {
    path: routePaths.settings,
    component: lazy(() => import("./_app/settings/settings")),
    layout: AppLayout,
  },
  {
    path: routePaths.tribe,
    component: lazy(() => import("./_app/tribe/tribe")),
    layout: AppLayout,
  },
  {
    path: routePaths.tribeRequests,
    component: lazy(() => import("./_app/tribe/requests")),
    exact: true,
    layout: AppLayout,
  },
  {
    path: [routePaths.tribeMember, routePaths.tribeRegister],
    component: lazy(() => import("./_app/tribe/member")),
    layout: AppLayout,
  },
  {
    path: routePaths.invoices,
    component: lazy(() => import("./_app/invoices/invoices")),
    layout: AppLayout,
  },
  {
    path: routePaths.services,
    component: lazy(() => import("./_app/services/services")),
    layout: AppLayout,
  },
  {
    path: [routePaths.serviceRegister, routePaths.serviceRegisterValidate],
    component: lazy(() => import("./_app/services/register")),
    layout: AppLayout,
  },
  {
    path: routePaths.documents,
    component: lazy(() => import("./_app/documents/documents")),
    layout: AppLayout,
  },
  {
    path: [routePaths.documentsNew, routePaths.documentsEdit],
    component: lazy(() => import("./_app/documents/edit")),
    layout: AppLayout,
  },
  {
    path: routePaths.messages,
    component: lazy(() => import("./_app/messages/messages")),
    layout: AppLayout,
  },
  {
    path: [routePaths.messagesCompose, routePaths.messagesReply],
    component: lazy(() => import("./_app/messages/create")),
    layout: AppLayout,
  },
  {
    path: routePaths.messagesDetail,
    component: lazy(() => import("./_app/messages/detail")),
    layout: AppLayout,
  },
  {
    path: [routePaths.provider],
    component: lazy(() => import("./_provider/home/home")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerChecking,
    component: lazy(() => import("./_provider/checking/checking")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerBilling,
    component: lazy(() => import("./_provider/billing/billing")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerStatisticsAnalytics,
    component: lazy(() => import("./_provider/statistics/analytics")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerStatisticsIncomes,
    component: lazy(() => import("./_provider/statistics/incomes")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerStatisticsBookings,
    component: lazy(() => import("./_provider/statistics/bookings")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerStatisticsSchedules,
    component: lazy(() => import("./_provider/statistics/schedules")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerStatisticsSociology,
    component: lazy(() => import("./_provider/statistics/sociology")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerExports,
    component: lazy(() => import("./_provider/exports/exports")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerSubscribers,
    component: lazy(() => import("./_provider/subscribers/subscribers")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerMessages,
    component: lazy(() => import("./_provider/messages/messages")),
    layout: ProviderLayout,
  },
  {
    path: [
      routePaths.providerMessagesCompose,
      routePaths.providerMessagesReply,
    ],
    component: lazy(() => import("./_provider/messages/create")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerMessagesDetail,
    component: lazy(() => import("./_provider/messages/detail")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerReleaseNote,
    component: lazy(() => import("./_provider/releaseNote/releaseNote")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerContact,
    component: lazy(() => import("./_provider/contact/contact")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerServices,
    component: lazy(() => import("./_provider/services/services")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerServiceDetail,
    component: lazy(() => import("./_provider/services/edit")),
    layout: ProviderLayout,
  },
  {
    path: routePaths.providerAccount,
    component: lazy(() => import("./_provider/account/account")),
    layout: ProviderLayout,
  },
  {
    path: "*",
    component: lazy(() => import("./components/notFound/notFound")),
    layout: LayoutBlank,
    exact: false,
    public: true,
  },
];
