export class tokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = "__AT__";

  public static hasAuthenticationStored(): boolean {
    return this.getToken() !== null;
  }

  public static storeToken(token: string, persist: boolean): void {
    if (persist) localStorage.setItem(tokenStorage.LOCAL_STORAGE_TOKEN, token);
    sessionStorage.setItem(tokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeTokenAuto(token: string): void {
    this.storeToken(token, this.hasAuthenticationStored());
  }

  public static clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  public static getToken(): string | null {
    const fromSession = sessionStorage.getItem(
      tokenStorage.LOCAL_STORAGE_TOKEN
    );

    return fromSession ?? localStorage.getItem(tokenStorage.LOCAL_STORAGE_TOKEN);
  }
}
