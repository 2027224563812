import { atom, DefaultValue, selector, selectorFamily } from "recoil";
import { ProvidersController } from "../apis/providersController";
import { IProvider } from "../models/Providers/GetAll/IProvider";
import { IService } from "../models/Providers/GetAll/IService";

const asyncDefault = selector<IProvider[] | null>({
  key: "providersStateAsyncDefault",
  async get() {
    try {
      return (await ProvidersController.getAll())?.providers;
    } catch (e) {
      console.error(e);
    }
    return null;
  },
});

export const providers = atom({
  key: "providers",
  default: asyncDefault,
});

export const providerName = selectorFamily<string | undefined, string>({
  key: "providerName",
  get: (providerId) => ({ get }) => {
    const available = get(providers);
    if (!available) return undefined;

    let provider = available.find((p) => p.id === providerId);
    return `${provider?.name}${provider?.subName && ` - ${provider?.subName}`}`;
  },
});

export const servicesPerProvider = selectorFamily<IService[], string | undefined>({
  key: "servicesPerProvider",
  get: (providerId) => ({ get }) => {
    const availableProviders = get(providers);
    if (availableProviders === null || availableProviders.length === 0) return [];

    let provider = availableProviders.find((p) => p.id === providerId);
    return provider?.services ?? [];
  },
});

export const providerMessages = selectorFamily<number, string | undefined>({
  key: "providerMessages",
  get: (providerId) => ({ get }) => {
    if (providerId === undefined)
      return 0;
    
    const available = get(providers);
    if (!available) return 0;

    let provider = available.find((p) => p.id === providerId);
    return provider?.messages ?? 0;
  },
  set: (providerId) => ({ get, set }, newValue) => {
    if (providerId === undefined)
      return;
    
    const available = get(providers);
    if (available) {
      set(providers, (existing) => {
        if (existing === null) return [];

        return existing.map((p) => {
          if (p.id === providerId) {
            return {
              ...p,
              messages: newValue instanceof DefaultValue ? 0 : newValue,
            };
          }
          return p;
        });
      });
    }
  },
});
