import React, { useEffect } from "react";
import {
  BrowserRouter as AppRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { routePaths, routes } from "../../routes";
import { useRecoilValue } from "recoil";
import { isLoggedIn } from "../../states/userState";

const Router = () => {
  const userIsLoggedIn = useRecoilValue(isLoggedIn);

  useEffect(() => {
    // Remove splash screen
    const splashScreen = document.querySelector(".app-splash-screen");
    splashScreen.style.opacity = 0;
    setTimeout(() => {
      if (splashScreen && splashScreen.parentNode) {
        splashScreen.parentNode.removeChild(splashScreen);
      }
    }, 300);
  }, []);

  const scrollTop = (
    to,
    duration,
    element = document.scrollingElement || document.documentElement
  ) => {
    if (element.scrollTop === to) return;
    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    if (!duration) {
      element.scrollTop = to;
      return;
    }

    // t = current time; b = start value; c = change in value; d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(
        easeInOutQuad(currentTime, start, change, duration)
      );
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  };

  return (
    <AppRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        {routes.map((route) => (
          <Route
            path={route.path}
            exact={typeof route.exact === "undefined" ? true : route.exact}
            render={(props) => {
              if (!route.public && !userIsLoggedIn) {
                return (
                  <Redirect
                    to={
                      route.path === routePaths.default ||
                      route.path === routePaths.login ||
                      route.path === routePaths.logout
                        ? routePaths.login
                        : `${routePaths.login}?redirect=${props.match.url}`
                    }
                  />
                );
              } else {
                // On small screens collapse sidenav
                if (
                  window.layoutHelpers &&
                  window.layoutHelpers.isSmallScreen()
                ) {
                  window.layoutHelpers.setCollapsed(true, false);
                }

                // Scroll page to top on route render
                scrollTop(0, 0);

                // Return layout
                return (
                  <route.layout {...props}>
                    <route.component {...props} scrollTop={scrollTop} />
                  </route.layout>
                );
              }
            }}
            key={route.path}
          />
        ))}
        {routePaths.default !== "/" && (
          <Redirect from="/" to={routePaths.default} exact={true} />
        )}
      </Switch>
    </AppRouter>
  );
};

export default Router;
