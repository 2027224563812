import React, { Component } from "react";
import Error from "../error/error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }
  componentDidCatch(error, info) {
    console.log(error, info);
    // TODO : logger les erreurs client
    //logErrorToMyService(error, info);
  }
  render() {
    if (this.state.hasError) {
      return <Error />
    }
    
    return this.props.children;
  }
}

export default ErrorBoundary;
