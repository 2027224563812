import { fr } from "date-fns/locale";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import Avatar from "react-avatar";
import { CoreController } from "../../apis/coreController";
import { timeTravelTo, timeTravelToNow } from "../../core/dateProvider";
import layoutHelpers from "../../core/layoutHelpers";
import { useRecoilState, useRecoilValue } from "recoil";
import { dateState } from "../../states/dateState";
import {
  loggedInUserFullName,
  loggedInUserPicture,
  loggedInUserIsProvider,
  loggedInUserIsDelegate,
  loggedInUserFirstProviderId, loggedInUserIsAdministrator
} from "../../states/userState";
import { routePaths } from "../../routes";

interface IProps {
  sidenavToggle?: boolean;
}

const LayoutNavbar = (props: IProps) => {
  const userFullName = useRecoilValue(loggedInUserFullName);
  const userPicture = useRecoilValue(loggedInUserPicture);
  const isAdministrator = useRecoilValue(loggedInUserIsAdministrator);
  const isProvider = useRecoilValue(loggedInUserIsProvider);
  const isDelegate = useRecoilValue(loggedInUserIsDelegate);
  const firstProviderId = useRecoilValue(loggedInUserFirstProviderId);
  const [currentDate, setCurrentDate] = useRecoilState(dateState);
  const location = useLocation();

  useEffect(() => {
    if (
      process.env.REACT_APP_DATE_PROVIDER === "true" &&
      currentDate === undefined
    ) {
      CoreController.getDate().then((result) => {
        setCurrentDate(result);
        if (result) {
          timeTravelTo(() => result);
        }
      });
    }
  }, [currentDate, setCurrentDate]);

  const toggleSidenav = (event: any) => {
    event.preventDefault();
    layoutHelpers.toggleCollapsed();
  };

  return (
    <Navbar
      bg="navbar-theme"
      expand="lg"
      className="layout-navbar align-items-lg-center container-p-x"
    >
      <Navbar.Brand
        as={NavLink}
        to={routePaths.default}
        className="app-brand demo d-lg-none py-0 mr-4"
      >
        <span className="app-brand-logo demo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 446 446"
            version="1.1"
            viewBox="0 0 446 446"
            xmlSpace="preserve"
          >
            <g>
              <path
                fill="#F4581B"
                d="M180.5 417.84L180.5 446 156.5 428 132.5 446 132.5 417.84 132.5 388.81 180.5 388.81z"
              />
              <path
                fill="#E6E6E6"
                d="M132.5 388.81v29.03h-21.47c-8.02 0-15.27-3.25-20.53-8.5-5.25-5.26-8.5-12.52-8.5-20.53h50.5z"
              />
              <path
                fill="#E6E6E6"
                d="M180.5 388.81H364V417.84000000000003H180.5z"
              />
              <path
                fill="#F2F2F2"
                d="M364 363.46v25.35H82c0-1.36.09-2.7.28-4.01 1.95-14.14 14.08-25.03 28.75-25.03H364v3.69z"
              />
              <path
                fill="#DDB74B"
                d="M111.03 0v359.77c-14.67 0-26.8 10.89-28.75 25.03l-.28-.03V29.03C82 13 95 0 111.03 0z"
              />
              <path fill="#008DB3" d="M323.06 73.2H364V146.4H323.06z" />
              <path fill="#F4581B" d="M323.06 219.6H364V292.8H323.06z" />
              <path
                fill="#F7CF52"
                d="M364 292.8L364 359.77 180.5 359.77 132.5 359.77 111.03 359.77 111.03 0 323.06 0 323.06 73.2 323.06 146.4 323.06 219.6 323.06 292.8z"
              />
              <path
                fill="#E6E6E6"
                d="M323.06 146.4H364V219.60000000000002H323.06z"
              />
              <path fill="#E6E6E6" d="M323.06 0H364V73.2H323.06z" />
            </g>
          </svg>
        </span>
        <span className="app-brand-text demo font-weight-normal ml-2">
          {layoutHelpers.isSmallScreen() ? "MPA" : "Mon Petit Agenda"}
        </span>
      </Navbar.Brand>

      {/* Sidenav toggle (see src/demo.css) */}
      {props.sidenavToggle && (
        <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
          <Nav.Item
            as="a"
            className="nav-link px-0 mr-lg-4"
            href="#toggle"
            onClick={toggleSidenav}
          >
            <i className="ion ion-ios-menu text-large align-middle" />
          </Nav.Item>
        </Nav>
      )}

      {layoutHelpers.isSmallScreen() ? (
        <Navbar.Toggle>
          <Avatar
            src={userPicture}
            name={userFullName}
            round={true}
            size="30"
          />
        </Navbar.Toggle>
      ) : (
        <Navbar.Toggle />
      )}
      {process.env.REACT_APP_DATE_PROVIDER === "true" && (isAdministrator || isProvider || isDelegate) &&
          location.pathname.startsWith("/provider") && (
        <Navbar.Collapse>
          <Nav className="align-items-lg-center ml-auto">
            <i className="mr-1">Forcer la date système</i>
            <DatePicker
              className="form-control" 
              locale={fr}
              selected={currentDate}
              isClearable={true}
              timeInputLabel="Heure"
              showTimeInput
              placeholderText="Ma date"
              onChange={async (date) => {
                await CoreController.setDate(date as Date);

                if (date) {
                  timeTravelTo(() => date as Date);
                  setCurrentDate(date as Date);
                } else {
                  timeTravelToNow();
                  setCurrentDate(null);
                }

                window.location.reload();
              }}
              dateFormat="dd/MM/yyyy, HH:mm"
            />
          </Nav>
        </Navbar.Collapse>
      )}

      <Navbar.Collapse>
        <Nav className="align-items-lg-center ml-auto">
          <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight>
            {layoutHelpers.isSmallScreen() ? (
              <>
                <Link to={routePaths.settings} className="dropdown-item">
                  <i className="fas fa-user-cog fa-fw text-light" /> &nbsp; Mes
                  paramètres
                </Link>
                <Dropdown.Divider />
                {(isAdministrator || isProvider || isDelegate) &&
                  (location.pathname.startsWith("/provider") ? (
                    <Link to={routePaths.default} className="dropdown-item">
                      <i className="fas fa-user-tag fa-fw" /> &nbsp; Mode abonné
                    </Link>
                  ) : (
                    <Link
                      to={routePaths.provider.replace(
                        ":providerId",
                        firstProviderId ?? ""
                      )}
                      className="dropdown-item"
                    >
                      <i className="fas fa-user-shield fa-fw" /> &nbsp; Mode
                      fournisseur
                    </Link>
                  ))}
                <Link to={routePaths.logout} className="dropdown-item">
                  <i className="fas fa-sign-out-alt fa-fw text-danger" /> &nbsp;
                  Déconnexion
                </Link>
              </>
            ) : (
              <>
                <Dropdown.Toggle as={Nav.Link}>
                  <span className="d-inline-flex align-items-center align-middle">
                    <Avatar
                      src={userPicture}
                      name={userFullName}
                      round={true}
                      size="30"
                    />
                    <span className="px-1 mr-lg-2 ml-2 ml-lg-2">
                      {userFullName}
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link to={routePaths.settings} className="dropdown-item">
                    <i className="fas fa-user-cog fa-fw text-light" /> &nbsp;
                    Mes paramètres
                  </Link>
                  <Dropdown.Divider />
                  {(isAdministrator || isProvider || isDelegate) &&
                    (location.pathname.startsWith("/provider") ? (
                      <Link to={routePaths.default} className="dropdown-item">
                        <i className="fas fa-user-tag fa-fw" /> &nbsp; Mode
                        abonné
                      </Link>
                    ) : (
                      <Link
                        to={routePaths.provider.replace(
                          ":providerId",
                          firstProviderId ?? ""
                        )}
                        className="dropdown-item"
                      >
                        <i className="fas fa-user-shield fa-fw" /> &nbsp; Mode
                        fournisseur
                      </Link>
                    ))}
                  <Link to={routePaths.logout} className="dropdown-item">
                    <i className="fas fa-sign-out-alt fa-fw text-danger" />{" "}
                    &nbsp; Déconnexion
                  </Link>
                </Dropdown.Menu>
              </>
            )}
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default LayoutNavbar;
